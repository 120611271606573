import * as Dialog from '@radix-ui/react-dialog';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';

import { IconSearch } from '../../assets/icons';
import noImagePlaceholder from '../../assets/no-image-placeholder.png';
import { IconSpinner, Modal, NamedLink, PaginationLinks, PrimaryButton } from '../../components';
import { useCreateDraftFromProduct } from '../../hooks/api/listings/useCreateDraftFromProduct';
import { useGetProductFilterOptions } from '../../hooks/api/listings/useGetProductFilterOptions';
import { useGetProducts } from '../../hooks/api/listings/useGetProducts';
import css from './ProductSearch.module.css';
import ButtonsNavigation, {
  ButtonsNavigationRenderContainer,
} from 'containers/EditListingPage/ButtonsNavigation/ButtonsNavigation';
import { useRouteConfiguration } from 'context/routeConfigurationContext';
import { useGetBrandIdOptions } from 'hooks/api/listings/useGetBrandIdOptions';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { useQueryParams } from 'hooks/useQueryParams';
import { pathByRouteName } from 'util/routes';

export const FILTERS = {
  departments: {
    title: 'Shop for',
    filterName: 'departmentId',
  },
  categories: {
    title: 'Category',
    filterName: 'categoryId',
  },
  colors: {
    title: 'Color',
    filterName: 'colorId',
  },
  productTypes: {
    title: 'Product type',
    filterName: 'productTypeId',
  },
  productSubtypes: {
    title: 'Product subtype',
    filterName: 'productSubtypeId',
  },
};

export const ProductSearch = props => {
  const currentUser = useCurrentUser();

  const [{ page, brandId }, setParams] = useQueryParams({ page: '1', brandId: '' });

  const [filters, setFilters] = useState({
    categoryId: '',
    departmentId: '',
    colorId: '',
    productTypeId: '',
    productSubtypeId: '',
  });
  const { data } = useGetBrandIdOptions();
  const brands = data?.brands || [];
  const brandName = brands.find(brand => brand.id === brandId)?.name || '';

  const [search, setSearch] = useState('');
  const [selectedProductId, setSelectedProductId] = useState(null);
  const debouncedSearch = useDebounce(search, 500);
  const { mutateAsync: createDraftFromProduct, isLoading: isCreatingDraft } =
    useCreateDraftFromProduct();

  // TODO: Filter out options that don't work together (categories, product types, subtypes etc.)

  useEffect(() => {
    if (page !== '1') {
      setParams({ page: '1' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.categoryId,
    filters.colorId,
    filters.departmentId,
    filters.productTypeId,
    filters.productSubtypeId,
  ]);

  const { data: filterOptions } = useGetProductFilterOptions();
  const { data: productsData, isSuccess } = useGetProducts({
    filters: {
      title: debouncedSearch.trim(),
      brandId,
      ...filters,
    },
    page: Number(page) || 1,
  });
  const products = productsData?.data;

  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();

  async function createProductDraft(product) {
    if (!currentUser.id) {
      const redirectPath = pathByRouteName('LoginPage', routeConfiguration);
      history.push(redirectPath);
    }
    // TODO: Add partner id
    const res = await createDraftFromProduct({ ...product, brandId, brandName });
    const id = res?.id?.uuid;

    if (id) {
      const redirectPath = pathByRouteName('EditListingPage', routeConfiguration, {
        slug: 'draft',
        id,
        type: 'draft',
        tab: 'photos',
      });
      history.push(redirectPath);
    }
  }

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const isDesktop = useMediaQueries({ viewport: 'medium' });
  const FilterComponent: React.FC = ({ children }) => {
    if (isDesktop) {
      return <>{children}</>;
    }

    return (
      <Modal open={filtersModalOpen} onOpenChange={setFiltersModalOpen} title="Product Filters">
        {children}
      </Modal>
    );
  };

  const productList = useMemo(() => {
    return products?.map(product => {
      return (
        <div
          className={css.productList__item}
          key={product.id}
          data-selected={Boolean(selectedProductId === product.id)}
          onClick={() => setSelectedProductId(prev => (prev === product.id ? null : product.id))}
        >
          <div className={css.productList__itemImage}>
            <picture>
              <source srcSet={product.photos?.[0]} />
              <img alt="product" src={noImagePlaceholder} />
            </picture>
          </div>
          <div className={css.productList__itemContent}>
            <span>{brandName}</span>
            <span>{product.title}</span>
            <span>
              {filterOptions
                ?.find(option => option.filterName === FILTERS.colors.filterName)
                ?.options?.find(option => option.id === product.colorId)?.label || ''}
            </span>
          </div>
        </div>
      );
    });
  }, [products, selectedProductId, filterOptions, brandName]);

  return (
    <div className={css.root}>
      <div className={css.header}>
        <div className={css.header__text}>
          Find the product you want to sell
          <br />
          or{' '}
          <NamedLink
            name="NewListingPage"
            style={{ textDecoration: 'underline' }}
            to={{ search: new URLSearchParams({ brandId }).toString() }}
          >
            List Manually
          </NamedLink>
        </div>
        <div className={css.header__searchPanel}>
          <div className={css.header__searchPanelContent}>
            <label className={css.searchWrapper}>
              <IconSearch />
              <input
                name="searchInput"
                type="text"
                placeholder="Search by product number, name or feature..."
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </label>
          </div>
        </div>
        <button className={css.header__filtersButton} onClick={() => setFiltersModalOpen(true)}>
          Filters
        </button>
      </div>
      <div className={css.mainContainer}>
        <div className={css.mainContainer__maxWidthWrapper}>
          <FilterComponent>
            <div className={css.dropdownsWrapper}>
              {filterOptions?.map(filter => {
                return (
                  <label key={filter.filterName}>
                    <select
                      name={filter.filterName}
                      value={filters[filter.filterName]}
                      onChange={e =>
                        setFilters({ ...filters, [filter.filterName]: e.target.value })
                      }
                    >
                      <option value="">{filters[filter.filterName] ? 'None' : filter.title}</option>
                      <hr />
                      {filter.options?.map(o => <option value={o.id}>{o.label}</option>)}
                    </select>
                  </label>
                );
              })}
            </div>
          </FilterComponent>
          <div className={css.productList}>
            {productList}
            {isCreatingDraft && (
              <Dialog.Root open>
                <Dialog.Portal>
                  <Dialog.Overlay>
                    <div className={css.formLoadOverlay}>
                      <IconSpinner />
                    </div>
                  </Dialog.Overlay>
                </Dialog.Portal>
              </Dialog.Root>
            )}
            {isSuccess && !products?.length && (
              <div className={css.noResults}>
                <span>No results found</span>
                <NamedLink
                  name="NewListingPage"
                  to={{ search: new URLSearchParams({ brandId }).toString() }}
                >
                  <PrimaryButton>List Manually</PrimaryButton>
                </NamedLink>
              </div>
            )}
            {Boolean(productsData?.metadata?.totalPages) && (
              <PaginationLinks
                className={css.pagination}
                pageName="ResellLandingPage"
                totalPages={productsData?.metadata?.totalPages || 0}
              />
            )}
          </div>
        </div>
        {/* TODO: Loading animation */}
        <ButtonsNavigation
          onLeftClick={() => {
            history.push(pathByRouteName('ResellLandingPage', routeConfiguration));
          }}
          onRightClick={() => {
            const isDisabled = !selectedProductId || isCreatingDraft;
            if (isDisabled) {
              toast.error('Please select a product or list manually');
            } else {
              const product = products?.find(product => product.id === selectedProductId);
              createProductDraft(product);
            }
          }}
        />
        <ButtonsNavigationRenderContainer />
      </div>
    </div>
  );
};

export default ProductSearch;
