import { useState } from 'react';

import { Modal } from '../../components';
import { useGeolocation } from 'hooks/useGeolocation';
import { isEUCountry } from 'translations/countryCodes';

export const ListFromEuModal = () => {
  const { userGeolocation } = useGeolocation();
  const [showModal, setShowModal] = useState(isEUCountry(userGeolocation));

  return (
    <Modal
      open={showModal}
      onOpenChange={setShowModal}
      title="Listing from the EU currently not available"
      showCloseButton={true}
      closeOnOutsideClick={false}
    >
      We currently support listing of items only in the UK. We apologise for the inconvenience and
      we hope to offer international listings in the near future!
      <br />
      <br />
    </Modal>
  );
};
